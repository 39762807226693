import "./Footer.css"

function Footer(){
    return (
        <div className="footer">
            © 2023 Zachary Lee Phy Action Physiotherapy All Rights Reserved
        </div>
    );
}

export default Footer